import './App.scss';
// redux
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './store';

import { ConfigProvider as AntDesignConfigProvider } from 'antd';
import tr_TR from 'antd/lib/locale/tr_TR';
import 'moment/locale/tr';
import { CheckboxPopupProvider } from './components/shared/CheckboxPopup/CheckboxPopup';
import { ConfirmationDialogProvider } from './components/shared/ConfirmationPopup/ConfirmationDialog';
import { CustomConfirmationDialogProvider } from './components/shared/ConfirmationPopup/CustomConfirmationDialog';
import { NotificationPopupProvider } from './components/shared/NotificationPopup/NotificationPopup';

import { env } from '@src/env';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import IntercomInitializer from './components/shared/IntercomInitializer';
import Router from './routers/router';
import theme from './theme.json';
export const queryClient = new QueryClient();
function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <IntercomProvider appId={env.REACT_APP_INTERCOM_APP_ID}>
              <IntercomInitializer />
              {/* <ReactNotification /> */}
              <AntDesignConfigProvider locale={tr_TR} theme={theme}>
                <ConfirmationDialogProvider>
                  <CustomConfirmationDialogProvider>
                    <NotificationPopupProvider>
                      <CheckboxPopupProvider>
                        <Router />
                      </CheckboxPopupProvider>
                    </NotificationPopupProvider>
                  </CustomConfirmationDialogProvider>
                </ConfirmationDialogProvider>
              </AntDesignConfigProvider>
            </IntercomProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
