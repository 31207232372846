import '@assets/sharedStyles/formItems.scss';
import '@assets/sharedStyles/pageItems.scss';
import UpdateModuleControlPriceAdmin from '@src/components/admin_components/UpdateModuleControlPriceAdmin/UpdateModuleControlPriceAdmin';
import ModuleSwitchButton from '@src/components/company_components/stockloss_kki/ModuleList/ModuleSwitchButton';
import { useConfirmationDialog } from '@src/components/shared/ConfirmationPopup/ConfirmationDialog';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import { axios_delete, axios_get } from '@src/utils/rest_axios';
import { Button, Collapse, Divider, Empty, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import AddModulePriceAdmin from '../../AddModulePriceAdmin/AddModulePriceAdmin';
import AddProductControlAdmin from '../../AddProductControlAdmin/AddProductControlAdmin';
import UpdateProductControlAdmin from '../../UpdateProductControlAdmin/UpdateProductControlAdmin';
import UpdateProductNameAdmin from '../../UpdateProductNameAdmin/UpdateProductNameAdmin';
import './AdminModuleList.scss';
import { ModuleNote } from '../../ModuleNote/ModuleNote';
const { Panel } = Collapse;

function AdminModuleList() {
  const [modules, setModules] = useState([]);
  const [reload, setReload] = useState(false);

  const { getNotification } = useNotificationPopup();
  const { getConfirmation } = useConfirmationDialog();

  useEffect(() => {
    async function getAllModules() {
      try {
        const result = await axios_get(`/admin/modules`);
        setModules(result.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getAllModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const getPanelHeader = (module, setReload) => {
    async function handleDelete(id) {
      const confirmed = await getConfirmation({
        title: `${module.name} Modül Aralığı Silinecek`,
        message: '',
        cancelText: 'Vazgeç',
        confirmText: 'Onayla'
      });
      if (confirmed) {
        try {
          await axios_delete(`/admin/modules/`, module.moduleId);
          message.success('Modül Silme Başarılı!', 5);
          setReload(!reload);
        } catch ({ response }) {
          message.error(
            'Modül Silinemedi. Modüle kayıtlı başvuru olmadığından emin olunuz!',
            5
          );
        }
      }
    }

    return (
      <div className="admin-module-list__header">
        <Row justify="space-between" className="admin-module-list__grid_field">
          <div className="module-list__name">{`${module.company.name} `}</div>
          <div className="admin-module-list__name">{`${module.name}`}</div>
          <div className="admin-module-list__name collapse_infos">
            <div className="collapse_infos_item">
              <ModuleSwitchButton
                reload={reload}
                moduleId={module.moduleId}
                current_status={module.status}
                setReload={setReload}
              />
            </div>
            <div className="collapse_infos_item">
              {epochToLocalFormatHelper(module.createdAt, 'dd.MM.yyyy')}
            </div>
            <div onClick={(e) => e.stopPropagation()}>
              <Button onClick={() => handleDelete(module.moduleId)} type="text">
                Sil
              </Button>
            </div>
          </div>
        </Row>
      </div>
    );
  };
  const getUpdateProductName = (module, setReload) => {
    return (
      <UpdateProductNameAdmin
        product_name={
          module.module_company_products[0]
            ? module.module_company_products[0].company_product.name
            : ''
        }
        companyProducts={module.module_company_products}
        companyId={module.company.companyId}
        reload={reload}
        callBack={setReload}
      />
    );
  };
  const getUpdateProductControl = (module, setReload) => {
    return (
      module.module_company_products.length > 0 &&
      module.module_company_products.map((moduleCompanyProduct) => {
        return (
          <div key={moduleCompanyProduct.modulesProductCompanyId}>
            <UpdateProductControlAdmin
              companyProduct={moduleCompanyProduct.company_product}
              reload={reload}
              callBack={setReload}
              companyId={module.company.companyId}
            />
          </div>
        );
      })
    );
  };
  const getPricesAndDates = (module, setReload) => {
    return (
      module.module_controls.length > 0 &&
      module.module_controls.map((module_control) => {
        return (
          <UpdateModuleControlPriceAdmin
            key={module_control.moduleControlId}
            module_control={module_control}
            callBack={setReload}
            reload={reload}
          />
        );
      })
    );
  };
  const getCollapseElements = (modules, setReload) => {
    if (modules.length > 0) {
      return modules.map((module) => (
        <Panel header={getPanelHeader(module, setReload)} key={module.moduleId}>
          <div className="module-list kemi_form_background">
            {/* medicine name */}
            {getUpdateProductName(module, setReload)}
            {/* gtins & product controls */}
            {getUpdateProductControl(module, setReload)}
            <AddProductControlAdmin
              module={module}
              callBack={setReload}
              reload={reload}
            />
            <Divider />
            {/* module_controls & price range */}
            {getPricesAndDates(module, setReload)}
            <AddModulePriceAdmin
              module={module}
              callBack={setReload}
              reload={reload}
            />
            <Divider />
            {/* Module note */}

            <ModuleNote module={module} callBack={setReload} reload={reload} />
          </div>
        </Panel>
      ));
    } else {
      return (
        <div>
          <Empty description="Bilgi Yok" />
        </div>
      );
    }
  };
  return (
    <div className="admin-module-list kemi_collapse">
      <Row justify="end">
        <b className="admin-module-list-text">Durum</b>
        <b className="admin-module-list-text">Oluşturulma Tarihi</b>
      </Row>
      <Collapse key="collapse">
        {getCollapseElements(modules, setReload)}
      </Collapse>
    </div>
  );
}

export default AdminModuleList;
