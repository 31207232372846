import { REQUIRED_INPUT_MESSAGE } from '@src/utils/formRules/formRules';
import { Button, Form, Input } from 'antd';
import './TrackerQueryLogin.scss';

function TrackerQueryLogin() {
  const [trackerLogin] = Form.useForm();

  function handleSubmit(values) {
    // console.log(values);
  }
  return (
    <div className="TrackerQueryLogin">
      <Form form={trackerLogin} autoComplete="off" onFinish={handleSubmit}>
        <h1>Giriş</h1>
        <Form.Item
          name="companyName"
          rules={[
            {
              required: true,
              message: REQUIRED_INPUT_MESSAGE,
            },
          ]}
        >
          <Input
            type="text"
            placeholder="Şirket İsmi"
            className="kemi_form_input"
          />
        </Form.Item>

        <Form.Item
          name="itsUserCode"
          rules={[
            {
              required: true,
              message: REQUIRED_INPUT_MESSAGE,
            },
          ]}
        >
          <Input
            type="number"
            placeholder="İTS Kullanıcı Kodu"
            className="kemi_form_input"
          />
        </Form.Item>

        <Form.Item
          name="itsPassword"
          rules={[
            {
              required: true,
              message: REQUIRED_INPUT_MESSAGE,
            },
          ]}
        >
          <Input
            type="password"
            placeholder="İTS Şifreniz"
            className="kemi_form_input"
          />
        </Form.Item>

        <Form.Item>
          <Button className="kemi_form_btn_purple" htmlType="submit">
            Çalıştır
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default TrackerQueryLogin;
