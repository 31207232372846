import RequestDetailCard from '@Components/shared/RequestDetailCard/RequestDetailCard';
import '@assets/sharedStyles/pageItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { axios_get } from '@utils/rest_axios';
import { statusMap } from '@utils/staticJsonObjects/status';
import { Table, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import PharmacyNewRequest from '../PharmacyNewRequest/PharmacyNewRequest';
const { TabPane } = Tabs;

const detail_card_type = 'pharmacy';
const columns = [
  {
    title: 'Başvuru No',
    dataIndex: 'requestId',
    render: (text, row) => {
      return row.requestId;
    }
  },

  {
    title: 'İşlem Adı',
    render: (obj, row) => {
      return row.module.name;
    }
  },
  {
    title: 'Firma Adı',
    render: (obj, row) => {
      return row.module.company.name;
    }
  },
  {
    title: 'Depo İsmi',
    render: (obj, row) => {
      return row.wholesaler ? row.wholesaler.name : '-';
    }
  },
  {
    title: 'Tutar (KDV Hariç)',
    dataIndex: 'totalPayment',
    render: (price, row) => {
      return formatTurkishCurrency(price, 2);
    }
  },
  {
    title: 'Ödeme Durumu',
    dataIndex: 'requestStatus',
    render: (status, row) => statusMap.get(status)
  },
  {
    title: '',
    dataIndex: '',
    render: (status, row) => (
      <span
        style={{
          textDecoration: 'underline',
          color: '#1890ff',
          letterSpacing: '0.5px'
        }}
      >
        Detaylar için tıklayınız.
      </span>
    )
  }
];

const PharmacyDashboard = () => {
  const [pendingPharmacyRequests, setPendingPharmacyRequests] = useState([]);
  const [oldPharmacyRequests, setOldPharmacyRequests] = useState([]);
  const [currentRequest, setCurrentRequest] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    const intervalId = setInterval(getPharmacyReqs, 5000);
    async function getPharmacyReqs() {
      try {
        const response = await axios_get('/pharmacypages/requests');
        setPendingPharmacyRequests(
          response.data.filter((request) => {
            return request['requestStatus'] === 'PENDING';
          })
        );
        setOldPharmacyRequests(
          response.data.filter((request) => {
            return request['requestStatus'] !== 'PENDING';
          })
        );
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getPharmacyReqs();
    return () => clearInterval(intervalId); //This is important
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (rowData) => {
    setCurrentRequest(rowData);
    setModalVisible(true);
  };

  return (
    <>
      <div className="kemi_pages_tabs">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Bekleyen Başvurular" key="1">
            <Table
              onRow={(rowData) => {
                return {
                  onClick: () => handleRowClick(rowData),
                  style: { cursor: 'pointer' }
                };
              }}
              columns={columns}
              dataSource={pendingPharmacyRequests}
              rowKey="requestId"
            />
          </TabPane>
          <TabPane tab="Geçmiş Başvurular" key="2">
            <div className="table-overflow-container">
              <Table
                onRow={(rowData) => {
                  return {
                    onClick: () => handleRowClick(rowData),
                    style: { cursor: 'pointer' }
                  };
                }}
                columns={columns}
                dataSource={oldPharmacyRequests}
                rowKey="requestId"
              />
            </div>
          </TabPane>
          <TabPane tab="Yeni Başvuru" key="3">
            <PharmacyNewRequest />
          </TabPane>
        </Tabs>
      </div>
      {modalVisible && (
        <RequestDetailCard
          setIsOpen={setModalVisible}
          isOpen={modalVisible}
          requestId={currentRequest.requestId}
          type={currentRequest.module.type}
          status={currentRequest.requestStatus}
          wholesaler={currentRequest?.wholesaler?.name || '-'}
          detail_card_type={detail_card_type}
          pageType="PHARMACY"
        />
      )}
    </>
  );
};

export default PharmacyDashboard;
