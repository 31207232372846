import '@assets/sharedStyles/pageItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@src/utils/rest_axios';
import { Empty, Row, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import './AdminDashboard.scss';
import AdminGraphCards from './AdminStatsParts/AdminGraphCards';
import AdminStatCard from './AdminStatsParts/AdminStatCard';
import AdminStatTitle from './AdminStatsParts/AdminStatTitle';
import AdminTotalCompanyStatCard from './AdminStatsParts/AdminTotalCompanyStatCard';
import AdminTotalUserCard from './AdminStatsParts/AdminTotalUserCard';
import { CompanyStats } from './CompanyStats';

const { TabPane } = Tabs;

function AdminDashboard() {
  const [stats, setStats] = useState({});

  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getAdminStats() {
      try {
        const result = await axios_get('/admin/stats');
        setStats(result.data);
      } catch ({ response }) {
        if (response) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getAdminStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="AdminDashboard kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Genel" key="1">
          <Row justify="start">
            <AdminStatTitle stats={stats} />
          </Row>

          <Row justify="space-around">
            <AdminStatCard stats={stats} />
            <AdminTotalUserCard stats={stats} />
            <AdminTotalCompanyStatCard stats={stats} />
          </Row>
          <AdminGraphCards />
        </TabPane>
        <TabPane tab="Firma Bazlı" key="2">
          <CompanyStats />
        </TabPane>
        <TabPane tab="Kontrol" key="3">
          <Empty description="Kontrol Bekleyen İşlem Bulunmamaktadır" />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminDashboard;
