import { Card } from 'antd';
import React from 'react';

const AdminTotalUserCard = ({ stats }) => {
  return (
    <Card className="StatCard__pharmacyInfo">
      <div className="StatCard__pharmacyInfo__content">
        <h1>
          <b> Kemi Kullanan Eczane Sayısı</b>
        </h1>
        {stats.numberOfPharmacies}
      </div>
      <div className="StatCard__pharmacyInfo__content">
        <h1>
          <br />
          <b>Günlük Giriş Sayısı</b>
        </h1>
        {stats.numberOfDailyLogins}
      </div>
    </Card>
  );
};

export default AdminTotalUserCard;
