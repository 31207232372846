import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import { axios_get } from '@utils/rest_axios';
import { Card, Empty } from 'antd';
import { useEffect, useState } from 'react';
import './SelectCompanyModules.scss';

const SelectCompanyModules = ({
  setRequestData,
  requestData,
  setCurrentStep
}) => {
  const [modules, setModules] = useState([]);
  console.log(modules);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getCompanyModules() {
      try {
        const companyModulesRes = await axios_get(
          `/pharmacypages/companymodules/${requestData.companyId}`
        );
        if (companyModulesRes.status === 200) {
          const filteredAndSortedModules = companyModulesRes.data
            .filter((module) => module.status === 'ACTIVE')
            .sort((a, b) => {
              const nameA = a.name.toLowerCase();
              const nameB = b.name.toLowerCase();

              return nameA.localeCompare(nameB);
            });
          setModules(filteredAndSortedModules);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getCompanyModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="selectCompanyModules">
      {modules.length > 0 ? (
        modules.map((module) => {
          return (
            <div
              key={module.moduleId}
              className="selectCompanyModules__company"
            >
              <Card
                hoverable
                className="selectCompanyModules__card"
                onClick={() => {
                  setRequestData({
                    ...requestData,
                    moduleGtins: module.gtins,
                    moduleId: module.moduleId,
                    type: module.type,
                    moduleName: module.name
                  });
                  setCurrentStep(2);
                }}
              >
                {module.name}
              </Card>
            </div>
          );
        })
      ) : (
        <Empty description="Bilgi Yok" />
      )}
    </div>
  );
};

export default SelectCompanyModules;
