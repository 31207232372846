import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { env } from '@src/env';
import { Button, Form, Input, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import axios from 'axios';
import React from 'react';

function HandleFailingRequests() {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      for (let product of values.products) {
        const params = new URLSearchParams();
        params.append('requestId', +values.requestId);
        params.append('barcode', product.barcode);
        params.append('page', product.page);
        params.append('transactions', product.transactions);
        await axios.post(
          `${env.REACT_APP_BACKEND_URL}/admin/manuel-scrape`,
          params,
          {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={{
        requestId: '',
        products: [{ key: 1, barcode: '', page: '' }],
      }}
    >
      <Form.Item name="requestId" label="Basvuru numarasi">
        <Input required type="number" />
      </Form.Item>

      <Form.List name="products">
        {(fields, { add, remove }) => (
          <>
            <Table
              dataSource={form.getFieldValue('products')}
              pagination={false}
              rowKey="key"
              className="table-top-align"
            >
              <Column dataIndex="key" key="key" title="Key" align="right" />

              <Column
                dataIndex="barcode"
                key="barcode"
                title="Barkod"
                render={(text, record, index) => (
                  <Form.Item
                    required
                    name={[index, 'barcode']}
                    fieldKey={[index, 'barcode']}
                  >
                    <Input />
                  </Form.Item>
                )}
              />

              <Column
                dataIndex="page"
                key="page"
                title="page"
                render={(text, record, index) => (
                  <Form.Item
                    required
                    name={[index, 'page']}
                    fieldKey={[index, 'page']}
                  >
                    <Input />
                  </Form.Item>
                )}
              />
              <Column
                dataIndex="transactions"
                key="transactions"
                title="transactions"
                render={(text, record, index) => (
                  <Form.Item
                    required
                    name={[index, 'transactions']}
                    fieldKey={[index, 'transactions']}
                  >
                    <Input />
                  </Form.Item>
                )}
              />

              <Column
                render={(text, record, index) => {
                  if (fields.length > 1) {
                    return (
                      <Form.Item>
                        <MinusCircleOutlined
                          className="delete-button"
                          style={{ margin: '0 8px' }}
                          onClick={() => {
                            remove(index);
                            form.setFieldsValue({
                              ...form.getFieldsValue(),
                              products: form
                                .getFieldValue('products')
                                .map((record, index) => {
                                  return {
                                    ...record,
                                    key: index + 1,
                                  };
                                }),
                            });
                          }}
                        />
                      </Form.Item>
                    );
                  } else return null;
                }}
              />
            </Table>

            <Form.Item>
              <Button
                type="dashed"
                onClick={() => {
                  add({
                    key: form.getFieldValue('products')?.length + 1,
                    barcode: '',
                    page: '',
                  });
                }}
                style={{ width: '100%' }}
              >
                <PlusOutlined />
                Add
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>

      {/* <Button type="primary" onClick={() => console.log(form.getFieldsValue())}>
        Print form state
      </Button> */}
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}

export default HandleFailingRequests;
