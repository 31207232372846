import { apiInstance } from '@src/api/axiosConfig';
import { usePost } from '@src/api/methods';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Input, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import './ITSStakeHoldersTab.scss';

const ITSStakeHolders = () => {
  const [isCreatingNewStakeHolder, setIsCreatingNewStakeHolder] =
    useState(false);
  const [searchedGlnValue, setSearchedGlnValue] = useState('');

  const [itsStakeHoldersForm] = Form.useForm();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  const { data: stakeholderValues } = useQuery({
    queryKey: ['stakeholder', searchedGlnValue],
    queryFn: () => apiInstance.get(`admin/stakeholder/${searchedGlnValue}`),
    enabled: !!searchedGlnValue,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (stakeholderValues) {
      itsStakeHoldersForm.setFieldsValue(stakeholderValues.data);
    }
    console.log(stakeholderValues);
  }, [itsStakeHoldersForm, stakeholderValues]);

  const { loading: stakeholderCreateLoading, mutate: createStakeholder } =
    usePost('/admin/stakeholder/');

  const { loading: stakeholderUpdateLoading, mutate: updateStakeholder } =
    useMutation({
      mutationKey: ['patch', 'stakeholder', searchedGlnValue],
      mutationFn: (payload) =>
        apiInstance.patch(`/admin/stakeholder/${searchedGlnValue}`, payload),
      onSuccess: () => {
        message.success(`Eczane güncelleme başarılı!`, 5);
      },
      onError: () => {
        message.error(`Eczane güncelleme başarısız!`, 5);
      }
    });

  const handleCreateStakeholder = () => {
    const formValues = itsStakeHoldersForm.getFieldsValue();
    createStakeholder(formValues, {
      onSuccess: () => {
        message.success(`Eczane oluşturma başarılı!`, 5);
      },
      onError: () => {
        message.error(`Eczane oluşturma başarısız!`, 5);
      }
    });
  };

  const handleUpdateStakeholder = () => {
    const formValues = itsStakeHoldersForm.getFieldsValue();
    const { companyName, ...formValuesWithoutCompanyName } = formValues;
    console.log(formValuesWithoutCompanyName);
    updateStakeholder(formValuesWithoutCompanyName);
  };

  const handleResetQueryAndStates = () => {
    setSearchedGlnValue('');
    setIsCreatingNewStakeHolder(false);
    queryClient.invalidateQueries(['stakeholder', searchedGlnValue]);
    itsStakeHoldersForm.resetFields();
  };
  console.log(searchedGlnValue);
  return (
    <>
      {!isCreatingNewStakeHolder && !stakeholderValues ? (
        <div className="intro-container" style={{ zIndex: 9999 }}>
          <div style={{ display: 'flex' }}>
            {/* TOD0 INPUT VALIDATION */}
            <Input
              type="number"
              ref={inputRef}
              placeholder="GLN"
              className="kemi_form_input"
            />
            <Button
              style={{ width: '7rem', marginLeft: '5px' }}
              className="kemi_form_btn_purple"
              onClick={() => {
                setSearchedGlnValue(inputRef.current.input.value);
              }}
            >
              Ara
            </Button>
          </div>
          <Button
            style={{ width: '290px', marginTop: '10px' }}
            className="kemi_form_btn_purple"
            onClick={() => setIsCreatingNewStakeHolder(true)}
          >
            Yeni Oluştur
          </Button>
        </div>
      ) : null}

      <div
        className="its-stake-holders-tab__"
        style={{
          filter:
            isCreatingNewStakeHolder || stakeholderValues
              ? 'none'
              : 'blur(8px)',
          pointerEvents:
            isCreatingNewStakeHolder || stakeholderValues ? 'auto ' : 'none'
        }}
      >
        <div className="labels-container">
          <label>GLN</label>
          <label>Eczane İsmi</label>
          <label>Eczacı İsmi</label>
          <label>Şehir</label>
          <label>İlçe</label>
          <label>Adres</label>
          <label>E-posta</label>
          <label>Telefon</label>
        </div>
        <div className="form-container">
          <Form
            name="itsStakeHolders"
            form={itsStakeHoldersForm}
            autoComplete="off"
          >
            <Form.Item
              name="gln"
              rules={[
                {
                  required: true,
                  message: GLN_NUMBER_REQUIRED_MESSAGE
                },
                {
                  min: 13,
                  message: GLN_NUMBER_DIGIT_MESSAGE
                },
                {
                  max: 13,
                  message: GLN_NUMBER_DIGIT_MESSAGE
                },
                {
                  pattern: GLN_NUMBER_REGEX,
                  message: GLN_NUMBER_PATTERN_MESSAGE
                }
              ]}
            >
              <Input type="number" className="kemi_form_input" />
            </Form.Item>
            <Form.Item
              name="companyName"
              rules={[
                {
                  required: true,
                  message: 'Eczane ismi giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item
              name="authorized"
              rules={[
                {
                  required: true,
                  message: 'Eczacı  ismi giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: 'Şehir giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item name="town">
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: 'Adres giriniz.'
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  ...EMAIL_REQUIRED,
                  message: EMAIL_MESSAGE
                }
              ]}
            >
              <Input className="kemi_form_input" />
            </Form.Item>
            <Form.Item name="phone">
              <Input className="kemi_form_input" />
            </Form.Item>
          </Form>
        </div>
        <div className="buttons-container">
          {isCreatingNewStakeHolder ? (
            <Button
              style={{ width: '7rem' }}
              className="kemi_form_btn_purple"
              onClick={handleCreateStakeholder}
              loading={stakeholderCreateLoading}
            >
              Oluştur
            </Button>
          ) : (
            <Button
              style={{ width: '7rem' }}
              className="kemi_form_btn_purple"
              onClick={handleUpdateStakeholder}
              loading={stakeholderUpdateLoading}
            >
              Güncelle
            </Button>
          )}
          <Button
            style={{ width: '7rem' }}
            className="kemi_form_btn_purple"
            onClick={handleResetQueryAndStates}
          >
            Geri
          </Button>
        </div>
      </div>
    </>
  );
};

export default ITSStakeHolders;
