import { DeleteOutlined, FileExcelOutlined } from '@ant-design/icons';
import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { Button, Row, Table } from 'antd';

const mockQueries = [
  {
    id: 1,
    fileName: 'DenemeSorgu1',
    owner: 'PharmaBest',
    date: 1652821863000,
    status: 'Devam Ediyor',
    noOfBarcodes: 50234
  },
  {
    id: 2,
    fileName: 'DenemeSorgu2',
    owner: 'PharmaBest',
    date: 1660339889000,
    status: 'Tamamlandı',
    noOfBarcodes: 23654
  }
];

function TrackerQueryResultTable() {
  return (
    <div>
      <Table columns={columns} dataSource={mockQueries} rowKey="wholesalerId" />
    </div>
  );
}

const columns = [
  {
    title: 'Dosya Adı',
    dataIndex: 'fileName'
  },
  {
    title: 'İTS Sahipliği',
    dataIndex: 'owner'
  },
  {
    title: 'Sorgu Tarihi',
    dataIndex: 'date',
    render: (timeStamp) => {
      return epochToLocalFormatHelper(timeStamp, 'dd.MM.yyyy');
    }
  },
  {
    title: 'Durum',
    dataIndex: 'status'
  },
  {
    title: 'Barkod Sayısı',
    dataIndex: 'noOfBarcodes'
  },
  {
    title: ' ',
    render: (text, row) => (
      <Row justify="space-around">
        <Button type="text">
          <FileExcelOutlined />
        </Button>
        <Button type="text">
          <DeleteOutlined />
        </Button>
      </Row>
    )
  }
];

export default TrackerQueryResultTable;
