import { Card, Col, Row } from 'antd';

const AdminStatCard = ({ stats }) => {
  return (
    <Card className="StatCard">
      <Row justify="space-around">
        <Col>
          <div className="StatCard__altTitles">
            <div>
              <h1>
                <b>Günlük</b>
              </h1>
            </div>
            <div>
              <h1>
                <b>Aylık</b>
              </h1>
            </div>
            <div>
              <h1>
                <b>Yıllık</b>
              </h1>
            </div>
          </div>
        </Col>
        {/* <Statistic title="Stok Zararı" value={18} />
        <Statistic title="KKİ" value={18} />
        <Divider type="vertical" />
        <Statistic title="Toplam" value={36} /> */}
      </Row>
    </Card>
  );
};

export default AdminStatCard;
