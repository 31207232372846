import React from 'react';

const AdminStatTitle = ({ stats }) => {
  return (
    <div className="StatCardTitle">
      <h1>
        <b>Toplam Kutu Sayısı:</b>
      </h1>
    </div>
  );
};

export default AdminStatTitle;
