import { format } from 'date-fns';
// import trTR from 'date-fns/locale/tr'
/**
 *
 * @param {*} epochTime 1652821863000
 * @param {*} dateFormat ex 'dd.MM.yyyy'
 * @returns formatted date with no local transformation
 */
export const epochToLocalFormatHelper = (epochTime, dateFormat) => {
  const dt = new Date(epochTime);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
  );

  return format(dtDateOnly, dateFormat);
};