import '@assets/sharedStyles/pageItems.scss';
import AdminKKIActiveRequests from '@src/components/admin_components/pharmacyRequests/AdminKKIActiveRequests/AdminKKIActiveRequests';

export default function AdminKKIRequestsPage() {
  return (
    <div className="kemi_pages_tabs">
      <AdminKKIActiveRequests />
    </div>
  );
}
