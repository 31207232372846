import ProblemKKI from './RequestDetailCardParts/KKİ/ProblemKKI';
import ProblemStock from './RequestDetailCardParts/Stock/ProblemStock';

const ProblemCard = ({ type, problemProducts }) => {
  return (
    <div className="requestDetailCard_popup_tabs_sections_content">
      {type === 'KKI' ? (
        <ProblemKKI problemProducts={problemProducts} />
      ) : (
        <ProblemStock problemProducts={problemProducts} />
      )}
    </div>
  );
};

export default ProblemCard;
