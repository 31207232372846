import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { Divider, Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
export const ApprovedStock = ({
  processedProducts,
  status,
  wholesaler,
  detail_card_type,
  papCode
}) => {
  const StocklossApprovedTableRows = () => {
    return (
      processedProducts.length > 0 &&
      processedProducts.map((product) => {
        return (
          <tr key={uuidv4()}>
            <td>
              {' '}
              <Input
                className="tr-input"
                readOnly
                type="text"
                value={product?.barcode ?? '-'}
              />
            </td>

            <td>
              {product?.product_detail?.pharmacyBuyDate
                ? epochToLocalFormatHelper(
                    new Date(+product?.product_detail?.pharmacyBuyDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>
            <td>
              {product?.product_detail?.pharmacySaleDate
                ? epochToLocalFormatHelper(
                    new Date(+product?.product_detail?.pharmacySaleDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>

            <td>
              <span className="th-price-tag">{product?.paymentAmount}</span>
            </td>
          </tr>
        );
      })
    );
  };

  function checkWholesalerType(wholesaler) {
    if (wholesaler) {
      if (wholesaler.name) {
        return wholesaler.name;
      } else {
        return wholesaler;
      }
    }
  }
  return (
    <div className="requestDetailCard_popup_table_container">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: '1rem',
          gap: '0.5rem'
        }}
      >
        {papCode && (
          <div style={{ marginTop: '1rem' }}>
            <span
              style={{
                color: 'white',
                backgroundColor: 'rgb(100 116 139)',
                borderRadius: '0.375rem',
                padding: '0.5rem',
                marginRight: '0.5rem'
              }}
            >
              Promosyon Kodu:
            </span>
            <span>{papCode}</span>
          </div>
        )}

        <div style={{ marginTop: '1rem' }}>
          <span
            style={{
              color: 'white',
              backgroundColor: 'rgb(100 116 139)',
              borderRadius: '0.375rem',
              padding: '0.5rem',
              marginRight: '0.5rem',
              marginTop: '0.5rem'
            }}
          >
            Depo Adı:
          </span>
          <span>{checkWholesalerType(wholesaler)}</span>
        </div>
        <Divider style={{ margin: '5px 0' }} />
      </div>
      <table>
        <thead>
          <tr>
            <th className="tr-head">Karekod</th>
            <th className="tr-head">Alış Tarihi</th>
            <th className="tr-head">Satış Tarihi</th>
            <th className="tr-head">Tutar (KDV Hariç)</th>
          </tr>
        </thead>
        <tbody>
          <StocklossApprovedTableRows />
        </tbody>
      </table>
    </div>
  );
};
