import { StarTwoTone } from '@ant-design/icons';
// import Grid from '@material-ui/core/Grid';
import Footer from '@src/components/public_components/Footer';
import PromotionCodeQuerySideBox from '@src/components/public_components/PromotionCodeQuerySideBox';
import { env } from '@src/env';
import { AUTH_SUCCESS } from '@src/redux/reducers/auth';
import { fetchUserDetails } from '@src/redux/thunks/userDetails';
import {
  EMAIL_MESSAGE,
  EMAIL_REQUIRED,
  PASSWORD_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post_public } from '@src/utils/rest_axios';
import { Button, Col, Form, Input, Row, Tabs } from 'antd';
import jwtDecode from 'jwt-decode';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import kemi_logo from '../../../assets/img/kemi_logo.png';
import loginImg from '../../../assets/img/loginImg.png';
import PharmacyRegister from '../../../components/pharmacy_components/PharmacyRegister';
import ActivationPage from '../ActivationPage/ActivationPage';
import ResetPasswordRequest from '../ResetPassword/ResetPasswordRequest';
import './LoginPage.scss';
import PharmacyLoginIssuesFlowModals from './PharmacyLoginIssuesFlowModals';
const { TabPane } = Tabs;

const LoginPage = () => {
  const [form] = Form.useForm();
  const [tab, setTab] = useState(1);

  const [inactivePharmacyValues, setInactivePharmacyValues] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loginErrorStake, setLoginErrorStake] = useState(false);
  const [
    isPharmacyLoginIssuesFlowModalVisible,
    setIsPharmacyLoginIssuesFlowModalVisible
  ] = useState(false);

  const [page_sections, setPage_sections] = useState('login-page'); // login-page || change-password-page
  const location = useLocation();
  const dispatch = useDispatch();

  const onFinish = async ({ email, password }) => {
    try {
      const res = await axios_post_public(`/login`, { email, password });
      const { role } = jwtDecode(res.data.accessToken);

      if (res.status === 200 && res.data.accessToken) {
        if (env.REACT_APP_IS_PHARMACY_REDIRECT_ENABLED === 'true') {
          if (role === 'Pharmacist') {
            window.location.href = `${env.REACT_APP_KEMI_PHARMACY_URL}?token=${res.data.accessToken}`;
            // window.location.href = `http://localhost:5173?token=${res.data.accessToken}`;
          } else {
            dispatch(AUTH_SUCCESS(res.data.accessToken));
            dispatch(fetchUserDetails());
          }
        } else {
          dispatch(AUTH_SUCCESS(res.data.accessToken));
          dispatch(fetchUserDetails());
        }
      }
    } catch (error) {
      if (
        error.response.data?.name === 'AccountNotActiveError' ||
        error.response.data?.name === 'NoUserFoundError'
      ) {
        setErrorMessage(error.response.data?.name);
        setInactivePharmacyValues({
          gln: error.response.data?.gln,
          email: error.response.data?.email
        });
        setIsPharmacyLoginIssuesFlowModalVisible((value) => !value);
      }
      if (error.response.data?.name === 'WrongCredentialsError') {
        setLoginErrorStake(true);
      }
      if (
        error.response.data?.name === 'WrongCredentialsError' &&
        loginErrorStake
      ) {
        setErrorMessage(error.response.data?.name);
        setIsPharmacyLoginIssuesFlowModalVisible((value) => !value);
      }
    }
  };

  function decideTab() {
    if (location.pathname === '/') {
      if (page_sections === 'login-page') {
        return <LoginForm />;
      } else if (page_sections === 'change-password-page') {
        return <ResetPasswordRequest setPage_sections={setPage_sections} />;
      }
    } else if (location.pathname === '/verify-account') {
      return <ActivationPage />;
    }
  }

  const handleModalVisible = () => {
    setIsPharmacyLoginIssuesFlowModalVisible((value) => !value);
  };

  function LoginForm() {
    return (
      <Col xs={20} sm={12} md={10} lg={10} xl={10}>
        <div className="loginpage__form__container">
          <div>
            <h1 className="loginpage__form__title">
              <span style={{ fontWeight: '600' }}>Kemi Portal'a</span>
              <br /> Hoş Geldiniz!
            </h1>
            <h2 className="loginpage__form__desc">
              Eczanelerle ilaç firmaları arasındaki
              <br />
              hızlı ve güvenli dijital veri köprüsü
            </h2>
          </div>
          <PharmacyLoginIssuesFlowModals
            inactivePharmacyValues={inactivePharmacyValues}
            errorMessage={errorMessage}
            isModalVisible={isPharmacyLoginIssuesFlowModalVisible}
            handleModalVisible={handleModalVisible}
          />
          <Tabs
            defaultActiveKey={tab}
            className="loginpage__form__tabs"
            size="large"
          >
            <TabPane tab="Giriş" key="1">
              <Form
                form={form}
                name="company-login"
                onFinish={onFinish}
                size="large"
                autoComplete="off"
              >
                <div style={{ width: '300px' }}>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_INPUT_MESSAGE
                      },
                      {
                        ...EMAIL_REQUIRED,
                        message: EMAIL_MESSAGE
                      }
                    ]}
                    hasFeedback
                  >
                    <Input
                      className="kemi_form_input_grey"
                      placeholder="E-posta Adresi"
                    />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_INPUT_MESSAGE
                      },
                      {
                        min: 6,
                        message: PASSWORD_MESSAGE
                      }
                    ]}
                    hasFeedback
                  >
                    <Input
                      className="kemi_form_input_grey"
                      type="password"
                      placeholder="Şifre"
                      required
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <div className="loginpage_btns">
                    <Button
                      shape="round"
                      className="loginpage__button__submit"
                      type="primary"
                      htmlType="submit"
                    >
                      Giriş
                    </Button>
                    <div
                      className="loginpage_forget_pwd_btn"
                      onClick={() => setPage_sections('change-password-page')}
                    >
                      Şifremi Unuttum
                    </div>
                  </div>
                </Form.Item>
              </Form>
            </TabPane>
            <TabPane tab="Kaydol" key="2">
              <PharmacyRegister setTab={setTab} />
            </TabPane>
          </Tabs>
        </div>
      </Col>
    );
  }

  function InfoSection() {
    return (
      <Col span={14} className="loginpage__img__breakpoint">
        <div className="loginpage__img__container">
          <img className="loginpage__img" src={loginImg} alt="loginpage_img" />

          <Row className="loginpage__form__gridImg" justify="center">
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">KVKK ile tam uyum</h3>
            </div>
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">
                Toplu bildirim kolaylığı
              </h3>
            </div>
            <div className="loginpage__img__stars__container">
              <StarTwoTone
                className="loginpage__img__stars__icon"
                twoToneColor="#F99A3E"
              />
              <h3 className="loginpage__img__stars__text">
                Yüksek kullanıcı deneyimi
              </h3>
            </div>
          </Row>
        </div>
      </Col>
    );
  }

  return (
    <>
      <div className="kemi_login_tab">
        <Row justify="end">
          <a href="/">
            <img src={kemi_logo} className="kemi_logo" alt="navbar-logo" />
          </a>{' '}
        </Row>

        <Row justify="space-around">
          <InfoSection />
          {decideTab()}
          {env.REACT_APP_IS_PAP_ACTIVE === 'true' ? (
            <PromotionCodeQuerySideBox />
          ) : null}
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default LoginPage;
