import '@assets/sharedStyles/formItems.scss';
import FormCityItem from '@src/components/company_components/pharmacy_reqs/PharmacyRequestsComponents/FormCityComponent';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { getErrorMessage } from '@src/utils/errors';
import { axios_post_download } from '@src/utils/rest_axios';
import { axios_get } from '@utils/rest_axios';
import { Button, DatePicker, Form } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import './AdminPharmacyRequestsFilter.scss';
import FilterFormCompanies from './FilterFormParts/FilterFormCompanies';
import FilterFormModuleId from './FilterFormParts/FilterFormModuleId';
import FilterFormPharmacy from './FilterFormParts/FilterFormPharmacy';
import FilterFormReqId from './FilterFormParts/FilterFormReqId';
import FilterFormStatus from './FilterFormParts/FilterFormStatus';
import FilterFormWholesaler from './FilterFormParts/FilterFormWholesaler';
const { RangePicker } = DatePicker;

function AdminPharmacyRequestsFilter({
  fetchRequests,
  type,
  parameters,
  setParameters
}) {
  const [form] = Form.useForm();
  const [modules, setmodules] = useState([]);
  const [wholesalers, setwholesalers] = useState([]);
  const [pharmacies, setpharmacies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [cities, setCities] = useState([]);

  const { getNotification } = useNotificationPopup();
  const endPointRoute =
    type === 'STOCK' || type === 'PAP'
      ? '/companypages/export/pharmacy-stock-requests'
      : '/companypages/export/pharmacy-kki-requests';
  useEffect(() => {
    async function getKkiModulesAndPharmacies() {
      try {
        const resModules = await axios_get('/admin/modules');
        const result =
          resModules.data.length > 0
            ? resModules.data.filter((module) => module.type === type)
            : [];
        setmodules(result);

        const resWholesalers = await axios_get('/admin/wholesaler');
        setwholesalers(resWholesalers.data);
        const resPharmacies = await axios_get('/admin/pharmacy');
        setpharmacies(resPharmacies.data);
        const resCompanies = await axios_get('/companies');
        setCompanies(resCompanies.data);
        const resCities = await axios_get('/cities');
        setCities(resCities.data);
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getKkiModulesAndPharmacies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Form
  const onFinish = async (values) => {
    const { dateRange = [], ...valuesExceptDateRange } = values;
    let startDate, endDate;

    if (dateRange?.length > 0) {
      const convertedDatesString = dateRange.map((moment) => {
        let date = new Date(moment);

        return epochToLocalFormatHelper(date.valueOf(), 'yyyy-MM-dd');
      });

      if (convertedDatesString.length === 2) {
        [startDate, endDate] = convertedDatesString;
      }
    }

    const preparedFormValues = { startDate, endDate, ...valuesExceptDateRange };
    let filterObject = _.pickBy(preparedFormValues, _.identity);

    if (filterObject.requestId) {
      filterObject.requestId = Number(filterObject.requestId);
    }
    filterObject.moduleType = type;

    setParameters(filterObject);
    fetchRequests(filterObject);
  };

  const handleXLSButtonOnClick = () => {
    axios_post_download(endPointRoute, { ...parameters, moduleType: type });
  };

  return (
    <div>
      <Form
        form={form}
        name="filter-form"
        onFinish={onFinish}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* Request No */}
          <FilterFormReqId />
          {/* Modul Name */}
          <FilterFormModuleId modules={modules} />
          {/* Companies */}
          <FilterFormCompanies companies={companies} />
          {/* Pharmacy Name */}
          <FilterFormPharmacy pharmacies={pharmacies} />
          {/* Wholesaler Name */}
          {type === 'STOCK' ||
            (type === 'PAP' && (
              <FilterFormWholesaler wholesalers={wholesalers} />
            ))}
          {/* Request Status */}
          <FilterFormStatus />
          {/* Cities */}
          <FormCityItem cities={cities} />
          {/* Date Range */}
          <Form.Item name="dateRange">
            <RangePicker
              format="DD.MM.YYYY"
              style={{ border: '1px solid #a29ece', borderRadius: '2px' }}
            />
          </Form.Item>
        </div>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          {/* Form Submit Item */}
          <Form.Item style={{ marginRight: '15px' }}>
            <Button className="kemi_form_btn_purple" htmlType="submit">
              Filtreleri Ara
            </Button>
          </Form.Item>
          {/* download xls */}
          <Button
            className="kemi_form_btn_purple"
            onClick={() => handleXLSButtonOnClick()}
          >
            .xls indir
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AdminPharmacyRequestsFilter;
