import { InboxOutlined } from '@ant-design/icons';
import { getErrorMessage } from '@src/utils/errors';
import { axios_post } from '@src/utils/rest_axios';
import { Modal, message } from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { useState } from 'react';
import * as XLSX from 'xlsx';
import { useNotificationPopup } from '../NotificationPopup/NotificationPopup';
import ModalContent from './ModalContent';
import './ReadExcelFromXls.scss';
import { warehouseHelpers } from './helper';

function ReadExcelFromXls({ setCallback, callback }) {
  const [accepted, setAccepted] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { getNotification } = useNotificationPopup();

  function readExcel(file) {
    try {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          raw: true,
          defval: null
        });
        const namedWholesaler = warehouseHelpers.changeRowAttributes(data);
        const validatedWholesalerRows =
          warehouseHelpers.validateRows(namedWholesaler);
        setAccepted(validatedWholesalerRows.accepted);
        setRejected(validatedWholesalerRows.rejected);

        // show modal
        showModal();
      };
    } catch (error) {
      message.error('Excel Yükleme Başarısız! Tekrar Deneyiniz.', 5);
    }
    // Prevent upload
    return false;
  }

  function removeExcel() {
    setAccepted([]);
    setRejected([]);
  }

  async function handlePostRequest(accepted) {
    if (accepted.length === 0) {
      message.error('Depo Oluşturulamadı!', 5);
    } else {
      const capitalizedAccepteds = capitalizeAttributes(accepted);
      let data = { wholesalers: capitalizedAccepteds };
      try {
        const wholesalerPostRes = await axios_post(
          '/companypages/wholesaler',
          data
        );
        if (wholesalerPostRes.status === 200) {
          message.success('Depo Oluşturma Başarılı!', 5);
          setCallback(!callback);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
  }

  function capitalizeAttributes(obj) {
    return obj.map((wholesaler) => {
      return {
        gln: wholesaler.gln,
        name: wholesaler.name
        // TODO add city attribute later
      };
    });
  }

  // modal functions

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);

    // send to backend
    handlePostRequest(accepted);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="ReadExcelFromXls">
      <Dragger
        accept=".xlsx, .xls"
        action=""
        type="file"
        name="xls"
        multiple={false}
        beforeUpload={readExcel}
        onRemove={removeExcel}
        onPreview={showModal}
        maxCount={1}
        listType="picture"
        className="ReadExcelFromXls--dragger "
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Çoklu Depo Yüklemek İçin Sürükle Bırak veya Tıkla Seç
        </p>
        <p className="ant-upload-hint">
          Tek dosya olarak <i>"Örnek .xls formatı indir"</i> formatındaki
          depoların bulunduğu excel dosyası(.xlsx) yüklenmelidir.
        </p>
      </Dragger>

      <div>
        <Modal
          title="Yükleme Sonuç Raporu"
          visible={isModalVisible}
          okText="Kaydet"
          onOk={handleOk}
          maskClosable={false}
          closable={false}
          onCancel={handleCancel}
        >
          <ModalContent accepted={accepted} rejected={rejected} />
        </Modal>
      </div>
    </div>
  );
}

export default ReadExcelFromXls;
