import ApprovedKKI from './RequestDetailCardParts/KKİ/ApprovedKKI';
import { ApprovedStock } from './RequestDetailCardParts/Stock/ApprovedStock';

const ApprovedCard = ({
  type,
  status,
  processedProducts,
  detailCardType,
  wholesaler,
  papCode
}) => {
  return (
    <div className="requestDetailCard_popup_tabs_sections_content">
      {type === 'KKI' ? (
        <ApprovedKKI
          processedProducts={processedProducts}
          detail_card_type={detailCardType}
          status={status}
        />
      ) : (
        <ApprovedStock
          processedProducts={processedProducts}
          status={status}
          wholesaler={wholesaler}
          detail_card_type={detailCardType}
          papCode={papCode}
        />
      )}
    </div>
  );
};

export default ApprovedCard;
