import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  COMPANY_ID_MESSAGE,
  DATE_REQUIRED_MESSAGE,
  GTIN_NUMBER_DIGIT_MESSAGE,
  GTIN_NUMBER_PATTERN_MESSAGE,
  GTIN_NUMBER_REGEX,
  GTIN_NUMBER_REQUIRED_MESSAGE,
  PRICE_REQUIRED_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_get, axios_post } from '@src/utils/rest_axios';
import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Space,
  message
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import { ModuleNotFieldFormItem } from '../../ModuleNote/ModuleNotFieldFormItem';
import './AdminNewRecord.scss';

const { Option } = Select;
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 8 },
  tail: { span: 24 }
};

const AdminNewRecord = () => {
  const [module_controls] = Form.useForm();
  const [companies, setCompanies] = useState([]);
  const { getNotification } = useNotificationPopup();

  useEffect(() => {
    async function getCompanies() {
      try {
        const companiesRes = await axios_get('/companies');
        if (companiesRes.status === 200) {
          setCompanies(companiesRes.data);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    }
    getCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SubmitForm = async (values) => {
    try {
      const now = moment();
      const payload = {
        companyId: values.companyId,
        name: `${values.name.toUpperCase()} `,
        type: values.type,
        products: values.products.map((product) => {
          return { name: values.drugname.toUpperCase(), gtin: product.gtin };
        }),
        moduleControls: values.module_controls.map((moduleControl) => {
          const priceBeginningWithTime = moment(
            moduleControl.priceBeginning
          ).set({
            hour: now.get('hour'),
            minute: now.get('minute'),
            second: now.get('second'),
            millisecond: now.get('millisecond')
          });
          return {
            // eslint-disable-next-line no-useless-escape
            price: +moduleControl.price.replace(/\./g, '').replace(/\,/g, '.'),
            priceBeginning: priceBeginningWithTime.valueOf()
          };
        }),
        moduleNote: values.moduleNote || undefined
      };
      await axios_post(`/admin/modules`, payload);
      message.success(
        'Modül başarıyla oluşturuldu! Mevcut kayıtlardan görüntüleyebilirsiniz. ',
        5
      );
      module_controls.resetFields();
    } catch ({ response }) {
      if (response && response.status !== 401) {
        getNotification({
          ...getErrorMessage(response)
        });
      }
      message.error('Modül Oluşturma Başarısız! Tekrar deneyiniz.', 5);
    }
  };
  // Form Parts

  function SelectCompany() {
    return (
      <Form.Item
        name="companyId"
        label="Firma İsmi"
        rules={[
          {
            required: true,
            message: COMPANY_ID_MESSAGE
          }
        ]}
      >
        <Select
          className="kemi_form_select_grey"
          allowClear
          showSearch
          maxTagCount="responsive"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.filter_name
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {companies.length > 0 &&
            companies.map((company) => {
              return (
                <Option
                  key={company.companyId}
                  value={company.companyId}
                  filter_name={company.name}
                >
                  {company.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
    );
  }

  const SelectModuleType = () => {
    return (
      <Form.Item
        name="type"
        label="İşlem Tipi"
        rules={[
          {
            required: true,
            message: 'İşlem tipi giriniz.'
          }
        ]}
      >
        <Select
          className="kemi_form_select_grey"
          allowClear
          showSearch
          maxTagCount="responsive"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.filter_name
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option key="KKI" value="KKI" filter_name="Kamu Kurum İskontosu">
            Alternatif Kamu Kurum İskontosu
          </Option>
          <Option key="STOCK" value="STOCK" filter_name="Stok Zararı">
            Stok Zararı
          </Option>
          <Option key="PAP" value="PAP" filter_name="PAP">
            PAP
          </Option>
        </Select>
      </Form.Item>
    );
  };

  const ProductNameField = () => {
    return (
      <Form.Item
        name="drugname"
        label="Ürün İsmi"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
      >
        <Input className="kemi_form_input_grey" />
      </Form.Item>
    );
  };
  const ProcessNameField = () => {
    return (
      <Form.Item
        name="name"
        label="İşlem İsmi"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
      >
        <Input className="kemi_form_input_grey" />
      </Form.Item>
    );
  };

  // TODO TARIH KONTROLLERI ILERIDE DEGISECEK

  const ProductGtinFormList = () => {
    return (
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="Form__align" key={key}>
                <input name="name" hidden />
                <Form.Item
                  {...restField}
                  name={[name, 'gtin']}
                  label="Ürün GTIN numarası"
                  rules={[
                    {
                      required: true,
                      message: GTIN_NUMBER_REQUIRED_MESSAGE
                    },
                    {
                      min: 14,
                      message: GTIN_NUMBER_DIGIT_MESSAGE
                    },
                    {
                      max: 14,
                      message: GTIN_NUMBER_DIGIT_MESSAGE
                    },
                    {
                      pattern: GTIN_NUMBER_REGEX,
                      message: GTIN_NUMBER_PATTERN_MESSAGE
                    }
                  ]}
                >
                  <Input
                    className="kemi_form_input_grey fixed_width_input Form_input"
                    type={'number'}
                  />
                </Form.Item>
                <MinusCircleOutlined
                  className="Form__minus__icon"
                  onClick={() => remove(name)}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="kemi_form_btn_purple"
              >
                GTIN Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const ModuleControlFormList = () => {
    return (
      <Form.List name="module_controls">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <Space
                key={key}
                className={('Form__space', 'Form__align')}
                align="baseline"
              >
                <div className="Form__align">
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label="Fiyat"
                    rules={[
                      {
                        required: true,
                        message: PRICE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <NumericFormat
                      customInput={Input}
                      className="kemi_form_input_grey Form__input__price"
                      thousandSeparator="."
                      decimalSeparator=","
                      decimalScale="2"
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'priceBeginning']}
                    label="Başlangıç Tarihi"
                    rules={[
                      {
                        required: true,
                        message: DATE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <DatePicker
                      className="Form__input__range kemi_form_input_grey"
                      format={'DD.MM.YYYY'}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    className="Form__minus__icon"
                    onClick={() => remove(name)}
                  />
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="kemi_form_btn_purple"
              >
                Fiyat Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };
  const CreateModuleForm = () => {
    return (
      <Form
        form={module_controls}
        name="dynamic_form_nest_item"
        initialValues={{ products: [''], module_controls: [''] }}
        onFinish={SubmitForm}
        autoComplete="off"
        // {...layout}
        colon={true}
        layout={'horizontal'}
        className="Form"
        wrapperCol={{
          md: { span: 16 }
        }}
      >
        <h3 className="kemi_form_highlighted_header">Firma Bilgileri</h3>
        <SelectCompany />
        <h3 className="kemi_form_highlighted_header">Ürün Bilgileri</h3>
        <SelectModuleType />
        <ProductNameField />
        <ProcessNameField />
        <ProductGtinFormList />
        <h3 className="kemi_form_highlighted_header">Fiyat Bilgileri</h3>
        <ModuleControlFormList />
        <h3 className="kemi_form_highlighted_header">
          Modül Bilgilendirme Metni
        </h3>

        <ModuleNotFieldFormItem />
        <Divider />
        <Form.Item {...layout.tail}>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    );
  };
  return (
    <Card className="newCompany">
      <CreateModuleForm />
    </Card>
  );
};

export default AdminNewRecord;
