import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { Input } from 'antd';
import { v4 as uuidv4 } from 'uuid';
export default function ApprovedKKI({
  processedProducts,
  status,
  detail_card_type
}) {
  function KkiApprovedTableRows() {
    return (
      processedProducts.length > 0 &&
      processedProducts.map((product) => {
        return (
          <tr key={uuidv4()}>
            <td>
              <Input
                className="tr-input"
                readOnly
                type="text"
                value={product?.barcode ?? '-'}
              />
            </td>
            <td>
              {product?.product_detail?.pharmacyBuyDate
                ? epochToLocalFormatHelper(
                    new Date(+product?.product_detail?.pharmacyBuyDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>
            <td>
              {product?.product_detail?.pharmacySaleDate
                ? epochToLocalFormatHelper(
                    new Date(+product?.product_detail?.pharmacySaleDate),
                    'dd.MM.yyyy'
                  )
                : '-'}
            </td>
            <td>{product?.product_detail?.pharmacySaleToName ?? '-'}</td>
            <td>{product.wholesaler ? product.wholesaler?.name : '-'}</td>

            <td>
              <span className="th-price-tag">
                {formatTurkishCurrency(product?.paymentAmount, 2)}
              </span>
            </td>
          </tr>
        );
      })
    );
  }

  return (
    <div className="requestDetailCard_popup_table_container">
      <table>
        <thead>
          <tr>
            <th className="tr-head">Karekod</th>
            <th className="tr-head">Alış Tarihi</th>
            <th className="tr-head">Satış Tarihi</th>
            <th className="tr-head">Satış Tipi</th>
            <th className="tr-head">Depo</th>
            <th className="tr-head">Tutar (KDV Hariç)</th>
          </tr>
        </thead>
        <tbody>
          <KkiApprovedTableRows />
        </tbody>
      </table>
    </div>
  );
}
