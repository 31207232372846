import { axios_get } from '@src/utils/rest_axios';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import AdminActivityTable from './AdminActivityTable';
import AdminCompanyActivityTable from './AdminCompanyActivityTable';
import AdminPharmacyActivityTable from './AdminPharmacyActivityTable';

const { TabPane } = Tabs;

function AdminUserActions() {
    const [activities, setActivities] = useState({
        Admin: [],
        Worker: [],
        Pharmacist: [],
    });
    useEffect(() => {
        async function fetchActivities() {
            const response = await axios_get('/admin/user-actions');
            if (response.status === 200) {
                const splitedActivities = categorizeAcitivities(response.data);
                //console.log(splitedActivities);
                setActivities(splitedActivities);
            }
        }
        fetchActivities();
    }, []);

    function categorizeAcitivities(activities) {
        return _.groupBy(activities, activity => activity?.user?.role?.name);
    }

    return (
        <div className="admin-company kemi_pages_tabs">
            <Tabs defaultActiveKey="1">
                <TabPane tab="Firma Hareketleri" key="1">
                    <AdminCompanyActivityTable activities={activities?.Worker} />
                </TabPane>
                <TabPane tab="Eczane Hareketleri" key="2">
                    <AdminPharmacyActivityTable activities={activities?.Pharmacist} />
                </TabPane>
                <TabPane tab="Admin Hareketleri" key="3">
                    <AdminActivityTable activities={activities?.Admin} />
                </TabPane>
            </Tabs>
        </div>
    );
}

export default AdminUserActions;
