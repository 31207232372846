import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import {
  GLN_NUMBER_DIGIT_MESSAGE,
  GLN_NUMBER_PATTERN_MESSAGE,
  GLN_NUMBER_REGEX,
  GLN_NUMBER_REQUIRED_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@utils/rest_axios';
import { validateObject } from '@utils/validate';
import { Button, Card, Form, Input, Row, Space, message } from 'antd';
import './CreateWholesaler.scss';

const layout = {
  labelCol: { span: 11 },
  wrapperCol: { span: 12 }
};

const CreateWholesaler = ({ setCallback, callback }) => {
  const [form] = Form.useForm();

  const { getNotification } = useNotificationPopup();

  const SubmitForm = async (values) => {
    if (validateObject(values) && values.wholesalers.length > 0) {
      const payload = values.wholesalers.map((wholesaler) => {
        // TODO backend degisince sehir de eklenecek!
        return {
          name: wholesaler.name.toUpperCase(),
          gln: wholesaler.gln
        };
      });
      // TODO: varolan isimde veya GLN i aynı olan oluşturulmaya çalışınca hata verelim
      try {
        const wholesalerPostRes = await axios_post('/companypages/wholesaler', {
          wholesalers: payload
        });
        if (wholesalerPostRes.status === 200) {
          message.success('Depo Oluşturma Başarılı!', 5);
          setCallback(!callback);
        }
      } catch ({ response }) {
        if (response && response.status !== 401) {
          getNotification({
            ...getErrorMessage(response)
          });
        }
      }
    } else {
      message.error('Depo Oluşturulamadı!', 5);
    }
  };

  const FormListItem = () => {
    return (
      <Form.List name="wholesalers">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space key={key} className="createWholesaler__space">
                <Row justify="space-between" className="createWholesaler__row">
                  <Form.Item
                    {...restField}
                    name={[name, 'gln']}
                    fieldKey={[fieldKey, 'gln']}
                    label="GLN Numarası"
                    rules={[
                      {
                        required: true,
                        message: GLN_NUMBER_REQUIRED_MESSAGE
                      },
                      {
                        min: 13,
                        message: GLN_NUMBER_DIGIT_MESSAGE
                      },
                      {
                        max: 13,
                        message: GLN_NUMBER_DIGIT_MESSAGE
                      },
                      {
                        pattern: GLN_NUMBER_REGEX,
                        message: GLN_NUMBER_PATTERN_MESSAGE
                      }
                    ]}
                  >
                    <Input type="number" className="kemi_form_input" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'name']}
                    fieldKey={[fieldKey, 'name']}
                    rules={[
                      {
                        required: true,
                        message: 'Ecza deposu adını giriniz.'
                      }
                    ]}
                    label="Ecza Deposu Adı"
                  >
                    <Input className="kemi_form_input kemi_form_input_uppercase" />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'city']}
                    fieldKey={[fieldKey, 'city']}
                    rules={[
                      {
                        required: true,
                        message: 'Ecza deposunun bulunduğu şehri giriniz.'
                      }
                    ]}
                    label="Ecza Deposu Şehir"
                  >
                    <Input className="kemi_form_input kemi_form_input_uppercase" />
                  </Form.Item>

                  <MinusCircleOutlined
                    className="formlist__minus__icon"
                    onClick={() => remove(name)}
                  />
                </Row>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="kemi_form_btn_purple"
              >
                Depo Satırı Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  return (
    <Card>
      <Form
        {...layout}
        name="update-module-control-name"
        form={form}
        onFinish={SubmitForm}
        initialValues={{ wholesalers: [undefined] }}
      >
        <FormListItem />
        <Form.Item>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Girilen Depoları Oluştur
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CreateWholesaler;
