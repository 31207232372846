import { Button } from 'antd';

const { Link } = require('react-router-dom');

export const ExampleXlsxDownload = () => {
  return (
    <Link
      className="exapmletxt--link"
      to="./files/depo_yukleme_ornek_dosya.xlsx"
      target="_blank"
      download
    >
      <Button className="kemi_form_btn_purple manageWarehouse_buttons">
        Örnek .xls formatı indir
      </Button>
    </Link>
  );
};
