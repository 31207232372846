import { Card } from 'antd';
import React from 'react';

const AdminTotalCompanyStatCard = ({ stats }) => {
  return (
    <Card className="StatCard__companyInfo">
      <div className="StatCard__companyInfo__content">
        <h1>
          <b> Firma Sayısı</b>
        </h1>
        {stats.numberOfCompanies}
      </div>
      <div className="StatCard__companyInfo__content">
        <h1>
          <br />
          <b> İşlemdeki Modül Sayısı</b>
        </h1>
        {stats.numberOfActiveModules}
      </div>
    </Card>
  );
};

export default AdminTotalCompanyStatCard;
