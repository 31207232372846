import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import '@assets/sharedStyles/formItems.scss';
import { ModuleNotFieldFormItem } from '@src/components/admin_components/ModuleNote/ModuleNotFieldFormItem';
import {
  DATE_REQUIRED_MESSAGE,
  GTIN_NUMBER_DIGIT_MESSAGE,
  GTIN_NUMBER_PATTERN_MESSAGE,
  GTIN_NUMBER_REGEX,
  GTIN_NUMBER_REQUIRED_MESSAGE,
  PRICE_REQUIRED_MESSAGE,
  REQUIRED_INPUT_MESSAGE
} from '@src/utils/formRules/formRules';
import { axios_post } from '@src/utils/rest_axios';
import {
  Button,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Space,
  message
} from 'antd';
import moment from 'moment';
import './NewRecord.scss';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 8 },
  tail: { span: 24 }
};

const NewRecord = ({ type, setReload, reload }) => {
  const [module_controls] = Form.useForm();

  const SubmitForm = async (values) => {
    try {
      const now = moment();
      const payload = {
        name: `${values.name.toUpperCase()}`,
        type: type,
        products: values.products.map((product) => {
          return { name: values.drugname.toUpperCase(), gtin: product.gtin };
        }),
        moduleControls: values.module_controls.map((moduleControl) => {
          const priceBeginningWithCurrentTime = moment(
            moduleControl.priceBeginning
          ).set({
            hour: now.get('hour'),
            minute: now.get('minute'),
            second: now.get('second'),
            millisecond: now.get('millisecond')
          });
          return {
            price: +moduleControl.price,
            priceBeginning: priceBeginningWithCurrentTime.valueOf()
          };
        }),
        moduleNote: values.moduleNote || undefined
      };
      await axios_post(`/companypages/modules`, payload);
      message.success(
        'Modül başarıyla oluşturuldu! Mevcut kayıtlardan görüntüleyebilirsiniz.',
        5
      );
      module_controls.resetFields();
      setReload(!reload);
    } catch (error) {
      message.error('Modül Oluşturma Başarısız! Tekrar deneyiniz.', 5);
    }
  };
  // Form Parts

  const ProductNameField = () => {
    return (
      <Form.Item
        name="drugname"
        label="Ürün İsmi"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
      >
        <Input className="kemi_form_input_grey" />
      </Form.Item>
    );
  };
  const ProcessNameField = () => {
    return (
      <Form.Item
        name="name"
        label="İşlem İsmi"
        rules={[
          {
            required: true,
            message: REQUIRED_INPUT_MESSAGE
          }
        ]}
      >
        <Input className="kemi_form_input_grey" />
      </Form.Item>
    );
  };

  const ProductGtinFormList = () => {
    return (
      <Form.List name="products">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
              <div className="Form__align" key={key}>
                <input name="name" hidden />
                <Form.Item
                  {...restField}
                  name={[name, 'gtin']}
                  label="Ürün GTIN numarası"
                  rules={[
                    {
                      required: true,
                      message: GTIN_NUMBER_REQUIRED_MESSAGE
                    },
                    {
                      min: 14,
                      message: GTIN_NUMBER_DIGIT_MESSAGE
                    },
                    {
                      max: 14,
                      message: GTIN_NUMBER_DIGIT_MESSAGE
                    },
                    {
                      pattern: GTIN_NUMBER_REGEX,
                      message: GTIN_NUMBER_PATTERN_MESSAGE
                    }
                  ]}
                >
                  <Input
                    className="kemi_form_input_grey fixed_width_input Form_input"
                    type={'number'}
                  />
                </Form.Item>
                <MinusCircleOutlined
                  className="Form__minus__icon"
                  onClick={() => remove(name)}
                />
              </div>
            ))}
            <Form.Item>
              <Button
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="kemi_form_btn_purple"
              >
                GTIN Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const ModuleControlFormList = () => {
    return (
      <Form.List name="module_controls">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <Space
                key={key}
                className={('Form__space', 'Form__align')}
                align="baseline"
              >
                <div className="Form__align">
                  <Form.Item
                    {...restField}
                    name={[name, 'price']}
                    label={
                      type === 'KKI'
                        ? 'İskonto'
                        : type === 'PAP'
                        ? 'Eczaneye Ödenecek Tutar'
                        : 'Fiyat'
                    }
                    rules={[
                      {
                        required: true,
                        message: PRICE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <InputNumber
                      className="kemi_form_input_grey Form__input__price"
                      type="number"
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, 'priceBeginning']}
                    label="Başlangıç Tarihi"
                    rules={[
                      {
                        required: true,
                        message: DATE_REQUIRED_MESSAGE
                      }
                    ]}
                  >
                    <DatePicker
                      className="Form__input__range kemi_form_input_grey"
                      format={'DD.MM.YYYY'}
                    />
                  </Form.Item>

                  <MinusCircleOutlined
                    className="Form__minus__icon"
                    onClick={() => remove(name)}
                  />
                </div>
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
                className="kemi_form_btn_purple"
              >
                Fiyat Ekle
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  };

  const CreateModuleForm = () => {
    return (
      <Form
        form={module_controls}
        name="dynamic_form_nest_item"
        initialValues={{ products: [''], module_controls: [''] }}
        onFinish={SubmitForm}
        autoComplete="off"
        colon={true}
        className="Form"
        wrapperCol={{
          md: { span: 16 }
        }}
      >
        <h3 className="kemi_form_highlighted_header">Ürün Bilgileri</h3>
        <ProductNameField />
        <ProcessNameField />
        <ProductGtinFormList />

        <h3 className="kemi_form_highlighted_header">
          {type === 'KKI' ? 'İskonto' : 'Fiyat'} Bilgileri
        </h3>
        {ModuleControlFormList()}
        <h3 className="kemi_form_highlighted_header">
          Modül Bilgilendirme Metni
        </h3>

        <ModuleNotFieldFormItem />

        <Divider />
        <Form.Item {...layout.tail}>
          <Button className="kemi_form_btn_dark" htmlType="submit">
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <div className="newRecord">
      <CreateModuleForm />
    </div>
  );
};

export default NewRecord;
