import { createAsyncThunk } from '@reduxjs/toolkit';
import { axios_get } from '@utils/rest_axios';

// Async thunk to fetch user details
export const fetchUserDetails = createAsyncThunk(
  'user/fetchDetails',
  async () => {
    const response = await axios_get('/me');
    console.log(response);
    const { data } = response;
    let currentUserValues;

    if (data.pharmacy) {
      currentUserValues = {
        userId: data.userId,
        email: data.email,
        gln: data.pharmacy.gln,
        phone: data.pharmacy.phone,
        name: data.pharmacy.name
      };
    } else {
      currentUserValues = {
        userId: data.userId,
        email: data.email,
        name: data.name,
        surname: data.surname
      };
    }

    return currentUserValues;
  }
);
