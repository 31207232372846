import { epochToLocalFormatHelper } from '@src/utils/date.helpers';

const { Table } = require('antd');

function AdminCompanyActivityTable({ activities }) {
  const companyActivitiesColumn = [
    {
      title: 'İşlem',
      dataIndex: 'note',
    },
    {
      title: 'İşlem tipi',
      dataIndex: 'actionType',
    },
    {
      title: 'İşlem içerigi',
      dataIndex: 'actionPayload',
    },
    {
      title: 'Kullanıcı E-postası',
      dataIndex: 'userEmail',
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (timeStamp ) => {
        return epochToLocalFormatHelper(timeStamp , 'dd.MM.yyyy-H:mm');
      },
    },
  ];

  return (
    <Table
      columns={companyActivitiesColumn}
      dataSource={activities}
      rowKey="actionId"
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}
    />
  );
}
export default AdminCompanyActivityTable;
