import React, { useState } from 'react';
import { axios_post } from '@utils/rest_axios';
import TableComponent from '@src/components/shared/TableComponent/TableComponent';
import KkiActiveFilter from './KkiActiveFilter';
import { statusMap } from '@utils/staticJsonObjects/status';
import { useNotificationPopup } from '@src/components/shared/NotificationPopup/NotificationPopup';
import { getErrorMessage } from '@src/utils/errors';
import './KkiActiveRequests.scss';
import { formatTurkishCurrency } from '@src/utils/numberFormats';
import { epochToLocalFormatHelper } from '@utils/date.helpers';

const detailCardType = 'kki';

const KkiActiveRequests = () => {
    const [filterParameters, setFilterParameters] = useState({});
    const [requestRows, setrequestRows] = useState([]);
    const [requestLoading, setRequestLoading] = useState(false);
    const { getNotification } = useNotificationPopup();

    const fetchRequests = async (parameters, paginationPage) => {
        setRequestLoading(true);
        try {
            const response = await axios_post(`/companypages/pharmacy-requests`, {
                ...(parameters ? parameters : filterParameters),
                offset: paginationPage ? paginationPage : 0,
                moduleType: 'KKI',
            });

            setrequestRows(response.data);
        } catch ({ response }) {
            if (response && response.status !== 401) {
                getNotification({
                    ...getErrorMessage(response),
                });
            }
        } finally {
            setRequestLoading(false);
        }
    };

    const columns = [
        {
            title: 'Başvuru No',
            dataIndex: 'requestId',
            render: (text, row) => row.requestId,
        },
        {
            title: 'Modül Adı',
            dataIndex: ['module', 'name'],
        },
        {
            title: 'Eczane GLN',
            dataIndex: ['pharmacy', 'gln'],
        },
        {
            title: 'Eczane Adı',
            dataIndex: ['pharmacy', 'name'],
        },
        {
            title: 'Şehir',
            dataIndex: ['pharmacy', 'address', 'city', 'cityName'],
        },
        {
            title: 'Başvuru Tarihi',
            dataIndex: 'applicationDate',
            render: text => epochToLocalFormatHelper(Number(text), 'dd.MM.yyyy'),
        },
        {
            title: 'Tutar (KDV Hariç)',
            dataIndex: 'totalPayment',
            render: text => formatTurkishCurrency(text, 2),
        },
        {
            title: 'Ödeme Durumu',
            dataIndex: 'requestStatus',
            render: text => statusMap.get(text),
        },
    ];

    return (
        <div>
            <KkiActiveFilter
                parameters={filterParameters}
                setParameters={setFilterParameters}
                fetchRequests={fetchRequests}
            />
            <TableComponent
                type="KKI"
                page_type="COMPANY"
                detailCardType={detailCardType}
                columns={columns}
                data_arr={requestRows}
                fetchRequests={fetchRequests}
                requestLoading={requestLoading}
                filterParameters={filterParameters}
            />
        </div>
    );
};

export default KkiActiveRequests;
