import '@assets/sharedStyles/pageItems.scss';
import AdminModuleList from '@src/components/admin_components/companyModule/CompanyModuleList/AdminModuleList';
import AdminNewRecord from '@src/components/admin_components/companyModule/NewCompanyModule/AdminNewRecord';
import { Tabs } from 'antd';

const { TabPane } = Tabs;

function AdminCompanyModule() {
  return (
    <div className="admin-company kemi_pages_tabs">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Yeni Kayıt" key="1">
          <AdminNewRecord />
        </TabPane>
        <TabPane tab="Mevcut Kayıtlar" key="2">
          <AdminModuleList />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default AdminCompanyModule;
