import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Navigate } from 'react-router-dom/dist';
import {
  adminPagesData,
  companyPagesData,
  pharmacyPagesData,
  publicPagesData
} from './pagesData';

const Router = () => {
  const auth = useSelector((state) => state.auth);

  const pagesByUserType = useMemo(() => {
    if (auth.isAuthenticated) {
      switch (auth.role) {
        case 'Pharmacist':
          return pharmacyPagesData;
        case 'Worker':
          return companyPagesData;
        case 'Admin':
          return adminPagesData;
        default:
          return [];
      }
    } else {
      return publicPagesData;
    }
  }, [auth.isAuthenticated, auth.role]);

  const pageRoutes = useMemo(() => {
    return pagesByUserType.map(({ path, title, element }) => (
      <Route key={title} path={`/${path}`} element={element} />
    ));
  }, [pagesByUserType]);

  const noMatchRoute = (
    <Route key={'notFound'} path="*" element={<Navigate to="/" replace />} />
  );

  return (
    <Routes>
      {pageRoutes}
      {noMatchRoute}
    </Routes>
  );
};

export default Router;
