import { epochToLocalFormatHelper } from '@src/utils/date.helpers';
import { Table } from 'antd';
import React from 'react';

function AdminActivityTable({ activities }) {
  const columns_admin_activity = [
    {
      title: 'İşlem',
      dataIndex: 'note',
    },
    {
      title: 'Kullanıcı E-postası',
      dataIndex: 'userEmail',
    },
    {
      title: 'Tarih',
      dataIndex: 'createdAt',
      render: (timeStamp ) => {
        return epochToLocalFormatHelper(timeStamp , 'dd.MM.yyyy-H:mm');
      },
    },
  ];

  return (
    <Table
      columns={columns_admin_activity}
      dataSource={activities}
      rowKey="actionId"
      pagination={false}
    />
  );
}

export default AdminActivityTable;
